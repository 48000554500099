<template>
  <div>
    <v-progress-linear
      indeterminate
      color="primary"
      :active="loading"
    ></v-progress-linear>

    <v-row v-if="!loading">
      <template v-if="turnos.length > 0">
        <v-col v-for="turno in turnos" :key="turno.id" cols="12" md="4">
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              color="blue"
              dark
              flat
            >
              <v-toolbar-title>{{ turno.inicio_dia_fmt }}</v-toolbar-title>
            </v-toolbar>

            <v-card-title>{{ turno.inicio_hora_fmt }}</v-card-title>

            <v-card-text>
              <div>
                <span class="text-capitalize">{{ $store.state.ajustes.label_medico }}</span>:
                <span v-html="turno.medico_nombre"></span>
              </div>
              <div>Consultorio: <span v-html="turno.consultorio_descripcion"></span></div>
              <div>Motivo: <span v-html="turno.motivo_nombre"></span></div>
            </v-card-text>

            <v-card-text v-if="parseInt(turno?.medico_limite_cancelacion || 0)" class="pt-0">
              Solo se puede cancelar con <span class="font-weight-bold">{{ turno.medico_limite_cancelacion }} {{ parseInt(turno.medico_limite_cancelacion) !== 1 ? 'horas' : 'hora' }}</span> de antelación.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                text
                color="primary"
                @click="onDeleteTurno(turno)"
              >
                Cancelar Turno
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
      <v-col v-else>
        <v-card
          flat
        >
          <v-card-text class="body-1">
            Usted no tiene turnos.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="100%"
      width="400"
      :persistent="sending"
      :style="{ zIndex: 200 }"
      @keydown.esc="dialog = false"
    >
      <v-card>
        <v-card-title style="word-break: break-word;">¿Cancelar el turno?</v-card-title>
        <v-card-text v-if="parseInt(turnoActivo?.medico_limite_cancelacion || 0)">
          Solo se puede cancelar con <span class="font-weight-bold">{{ turnoActivo.medico_limite_cancelacion }} {{ parseInt(turnoActivo.medico_limite_cancelacion) !== 1 ? 'horas' : 'hora' }}</span> de antelación.
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            :disabled="sending"
            @click="dialog = false"
            >Cancelar</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            :loading="sending"
            @click="deleteTurno"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  data: () => ({
    loading: false,
    sending: false,
    dialog: false,

    turnos: [],
    turnoActivo: {},
  }),

  methods: {
    query () {
      this.loading = true

      let filters = {
        date_from: new Date().toISOString().substr(0, 10),
        paciente_id: this.$store.state.paciente
      };

      return this.$http.get('turnos', {params: filters})
      .then((response) => {
        const today = moment();

        this.turnos = response.data.filter(turno =>
          moment(turno.inicio, "YYYY-MM-DD HH:mm:ss").isAfter(today)
        );
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .then(() => {
        this.loading = false;
      })
    },

    onDeleteTurno(item) {
      this.dialog = true
      this.turnoActivo = item
    },

    deleteTurno () {
      this.sending = true

      this.$http.delete('turnos/'+this.turnoActivo.id)
        .then(() => {
          this.query();
        })
        .catch((error) => {
          this.$eventHub.$emit(
            'snackbar-message',
            error?.response?.data?.message || 'No se pudo procesar su solicitud',
            'error'
          )
        })
        .finally(() => {
          this.dialog = false
          this.sending = false
        })
    },
  },

  created () {
    this.query()
  },
}
</script>
